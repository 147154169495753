import gql from "graphql-tag";

const GET_VERIFIED_USERS = gql`
  query getVerifiedUsers {
    getVerifiedUsers {
      id
      first_name
      last_name
    }
  }
`;

const GET_PRODUCTS_CALENDAR = gql`
  query getProducts($userId: Int, $page: Int, $perPage: Int, $sortCreatedAt: Int, $status: SortProductStatus, $search: String) {
    getProducts(
      userId: $userId
      page: $page
      perPage: $perPage
      sortCreatedAt: $sortCreatedAt
      status: $status
      search: $search
    ) {
      total
      items {
        id
        user_id
        translation {
          id
          title
        }
        currency
        default_price
      }
    }
  }
`;

const GET_UNITS = gql`
  query getUnits($parent_id: Int!) {
    getUnits(parent_id: $parent_id) {
      id
      translation {
        title
      }
      price_settings {
        active
        id
        price
        value
        type
      }
    }
  }
`;

const GET_EVENTS_AND_PRICES = gql`
  query getEventsAndPrices($parent_id: Int!, $product_id: Int!, $range: CalendarDateRange) {
    getEvents(parent_id: $parent_id, product_id: $product_id, range: $range) {
      type
      preparation {
        from_date
        to_date
      }
      booking_id
      id
      payment_id
      payment {
        uuid
        customer_info {
          first_name
          last_name
          number_of_people
        }
      }
      from_date
      to_date
      product {
        translation {
          title
        }
        parent {
          category {
            media {
              media {
                path
              }
            }
          }
        }
        preparation_after_booking
        preparation_before_booking
      }
      description
      external_calendar {
        title
        channel {
          title
        }
      }
      inIcal
      comments {
        id
        content
      }
    }
    getSeasonPrice(product_id: $product_id, range: $range) {
      date_from
      date_to
      id
      price
    }
  }
`;

const GET_PAYMENT_BY_ID = gql`
  query getPaymentById($payment_id: Int!) {
    getPaymentById(payment_id: $payment_id) {
      id
      uuid
      landlord {
        id
        first_name
        last_name
        email
      }
      cancel_reason
      pricing {
        code
        symbol
        price
      }
      created_at
      status
      customer_info {
        id
        number_of_people
        first_name
        last_name
        tel
        email
        additional_info
        city
        postal_code
        region
        street
      }
      bookings {
        id
        comments {
          id
          content
          created_at
        }
        cancelled
        cancel_reason
        from_date
        to_date
        product {
          translation {
            title
          }
          parent {
            translation {
              title
            }
            check_in
            check_out
            address
            location
            phone
            urlm {
              url
            }
            media {
              media {
                filename
                path
              }
            }
          }
        }
        comments {
          content
        }
        price
      }
      customer_addons {
        qty
        price
        addon {
          translation {
            title
            description
          }
          pricing {
            code
            symbol
            price
          }
        }
      }
      gift_card {
        code
        isPercentage
        amountToRedeem
      }
      price
      currency
    }
  }
`;

export { GET_VERIFIED_USERS, GET_PRODUCTS_CALENDAR, GET_EVENTS_AND_PRICES, GET_UNITS, GET_PAYMENT_BY_ID };
