<template>
  <div>
    <FullCalendar :options="calendarOptions" />
    <BookingSidebarModal
      v-if="snowBookingModal"
      :snowBookingModal="snowBookingModal"
      :paymentId="currentPayment"
      @closeModal="closeModalHandler" />
    <ExternalEventModal
      v-if="showExternalEventModal"
      :snowExternalEvent="showExternalEventModal"
      :externalEvent="selectedEvent"
      @closeModal="closeExternalEventModal" />
    <CalendarActionsSidebarModal
      v-if="isShowActionModal"
      :key="'actionModal'"
      :showActionModal="isShowActionModal"
      :productId="unit.id"
      :range="selectedRange"
      :price="priceOfSelectedRange"
      :selectedRangeInfo="infoAboutSelectedRange"
      @closeActionModal="closeActionModalHandler"
      @refetchEvents="$apollo.queries.calendarEventsAndPrices.refetch()" />
  </div>
</template>

<script>
import "@fullcalendar/core/vdom";
import FullCalendar from "@fullcalendar/vue3";
import dayGridPlugin from "@fullcalendar/daygrid";
import interactionPlugin from "@fullcalendar/interaction";
import timeGridPlugin from "@fullcalendar/timegrid";
import listPlugin from "@fullcalendar/list";
import { GET_EVENTS_AND_PRICES } from "@/graphql/calendar/queries";
import BookingSidebarModal from "@/components/Bookings/BookingSidebarModal";
import CalendarActionsSidebarModal from "@/components/Bookings/CalendarActionsSidebarModal";
import ExternalEventModal from "@/components/Bookings/ExternalEventModal";
import dayjs from "dayjs";
import isBetween from "dayjs/plugin/isBetween";

export default {
  name: "Calendar",
  components: {
    BookingSidebarModal,
    CalendarActionsSidebarModal,
    ExternalEventModal,
    FullCalendar
  },
  props: {
    hostId: {
      type: Number,
      required: true
    },
    productId: {
      type: [Number, null],
      required: true
    },
    unit: {
      type: [Object, null],
      required: true
    },
    defaultPrice: {
      type: Object,
      required: true
    }
  },
  apollo: {
    calendarEventsAndPrices: {
      query: GET_EVENTS_AND_PRICES,
      update: (data) => data.getEvents,
      variables() {
        return {
          parent_id: this.productId,
          product_id: this.unit.id,
          range: {
            date_from: this.dateRange.from_date,
            date_to: this.dateRange.to_date
          }
        };
      },
      fetchPolicy: "network-only",
      result(result) {
        const { data: { getEvents = [], getSeasonPrice = [] } = {} } = result;

        this.seasonPrices = getSeasonPrice;

        const bookings = getEvents.filter((event) => event.type === "booking");

        const preparedEventWithoutCross = getEvents.filter((event) => {
          if (event.type === "external") {
            return !bookings.some((internalEvent) => internalEvent.from_date === event.from_date);
          }

          return true;
        });

        this.calendarOptions.events = [
          ...preparedEventWithoutCross.map((event) => {
            if (event.type === "booking") {
              return {
                id: event.id,
                groupId: event.id,
                title: `${event.payment.customer_info.first_name} ${event.payment.customer_info.last_name}`,
                start: event.from_date,
                end: event.to_date,
                className: "calendar-booking",
                display: "background",
                extendedProps: {
                  type: event.type,
                  paymentId: event.payment_id,
                  price: this.defaultPrice.amount,
                  code: this.defaultPrice.code,
                  guests: event.payment.customer_info.number_of_people,
                  nights: this.difference(event.from_date, event.to_date)
                }
              };
            }

            if (event.type === "preparation_before") {
              return {
                id: event.id,
                groupId: event.booking_id,
                title: event.booking_id,
                start: event.from_date,
                end: event.to_date,
                display: "none",
                extendedProps: {
                  type: event.type
                }
              };
            }

            if (event.type === "external") {
              return {
                id: event.id,
                start: event.from_date,
                end: event.to_date,
                title: event.external_calendar.channel.title,
                className: "calendar-external",
                display: "background",
                extendedProps: {
                  type: event.type,
                  description: event.description
                }
              };
            }

            if (event.type === "block") {
              return {
                id: event.id,
                start: event.from_date,
                end: event.to_date,
                title: event.product.translation.title,
                className: "calendar-block",
                display: "background",
                extendedProps: {
                  type: event.type,
                  inIcal: event.inIcal,
                  comments: event.comments
                }
              };
            }

            if (event.type === "checkout") {
              return {
                id: event.id,
                start: event.from_date,
                end: event.to_date,
                title: event.product.translation.title,
                className: "calendar-checkout",
                display: "background",
                extendedProps: {
                  type: event.type,
                  comments: event.comments
                }
              };
            }

            return null;
          })
        ].filter((event) => event !== null);
      },
      skip() {
        return this.dateRange === null || this.productId === null;
      }
    }
  },
  data() {
    return {
      dayjs,
      dateRange: null,
      isCellClick: true,
      hasClicked:false,
      calendarOptions: {
        plugins: [listPlugin, dayGridPlugin, interactionPlugin, timeGridPlugin],
        initialView: "dayGridMonth",
        selectable: true,
        selectOverlap: true,
        selectAllow: (selectInfo) => {
          this.isCellClick = dayjs(selectInfo.end).diff(selectInfo.start, "days") < 1;
          return true;
        },
        select: this.selectRange,
        dateClick: this.selectRange,
        eventContent(arg) {
          if (arg.event.extendedProps.type === "booking") {
            return {
              html: `<div class="p-1 pt-6">
                              <div>
                                <p class="font-medium text-xs md:text-sm truncate">${arg.event.title}</p>
                              </div>
                              <div class="md:pt-2 pt-1 md:text-sm text-xs hidden sm:block">
                                <p>${arg.event.extendedProps.guests} guests</p>
                                <p class="md:pt-1 pt-0.5">${arg.event.extendedProps.nights}</p>
                              </div>
                            </div>`
            };
          }

          if (arg.event.extendedProps.type === "external") {
            return {
              html: `<div style="position: absolute; bottom: 15px;">
                              <p style="color: #151514;
                              padding: 3px 5px;
                              background-color: white;
                              border-radius: 4px">${arg.event.title}</p>
                            </div>`
            };
          }

          return null;
        },
        headerToolbar: {
          start: "dayGridMonth,dayGridWeek",
          center: "prev,title,next",
          end: ""
        },
        buttonText: {
          month: "Month",
          week: "Week"
        },
        slotDuration: "00:15:00",
        navLink: true,
        dayMaxEventRows: 8,
        eventTimeFormat: {
          hour: "2-digit",
          minute: "2-digit",
          meridiem: false
        },
        displayEventTime: true,
        displayEventEnd: true,
        events: [],
        dayHeaderFormat: { weekday: "long" },
        firstDay: 1,
        datesSet: async ({ start, end }) => {
          const isStartEqual =
            this.dateRange &&
            new Date(this.dateRange.from_date).getMonth() === new Date(start).getMonth() &&
            new Date(this.dateRange.from_date).getDate() === new Date(start).getDate();

          const isEndEqual =
            this.dateRange &&
            new Date(this.dateRange.to_date).getMonth() === new Date(end).getMonth() &&
            new Date(this.dateRange.to_date).getDate() === new Date(end).getDate();

          if (isStartEqual && isEndEqual) {
            return;
          }

          this.dateRange = {
            from_date: start,
            to_date: end
          };
        },
        dayCellClassNames: (arg) => {
          const bookingDays = this.calendarOptions.events.filter((event) => event.extendedProps.type === "booking");
          const preparation = this.calendarOptions.events.filter((event) => event.extendedProps.type === "preparation_before");
          const isBookedDay = bookingDays.some((block) => dayjs(arg.date).isBetween(block.start, block.end, null, "[)"));
          const isPreparation = preparation.some((block) => dayjs(arg.date).isBetween(block.start, block.end, null, "[)"));
          return ["calendar-cell", isBookedDay ? "calendar-cell-booking" : "", isPreparation ? "preparation-before" : ""];
        },
        dayCellContent: (arg) => {
          dayjs.extend(isBetween);
          const blockDays = this.calendarOptions.events.filter((event) => event.extendedProps.type !== "booking");
          const isBlockedDay = blockDays.some((block) => dayjs(arg.date).isBetween(block.start, block.end, null, "[)"));
          const isBooking = this.calendarOptions.events
            .filter((event) => event.extendedProps.type === "booking")
            .some((event) => dayjs(arg.date).isBetween(event.start, event.end, null, "[)"));
          const seasonPrice = this.seasonPrices.find((price) =>
            dayjs(arg.date).isBetween(price.date_from, price.date_to, null, "[)")
          );

          const priceSettingsEvents = (this.unit.price_settings ?? []).filter((setting) => setting.type === 3 && setting.active);

          const priceForCouncretDay = priceSettingsEvents.find((event) => event.value === arg.dow);

          let priceForDisplay;

          if (seasonPrice) {
            priceForDisplay = seasonPrice.price;
          } else if (priceForCouncretDay) {
            this.daysWithSmartPricing.push({
              date: arg.date,
              price: priceForCouncretDay.price
            });
            priceForDisplay = priceForCouncretDay.price;
          } else {
            priceForDisplay = this.defaultPrice.amount;
          }

          return {
            html: `<div class="px-1 py-0.5 sm:px-2 sm:py-1" style="z-index: 10;">
                     <div class="day-number">
                       <div>
                         <span style="text-decoration: ${isBlockedDay ? "line-through" : "none"}">${arg.dayNumberText}</span>
                         <span style="margin-left: 5px; display: ${arg.isToday ? "inline-block" : "none"}">
                            ${arg.isToday ? "Today" : ""}</span>
                        </div>
                     </div>

                      <div class="${isBooking ? "bottom-1 right-2 sm:top-1 sm:right-2" : "md:bottom-12"} absolute">
                        <span
                        class="text-sm"
                        style="display: ${!isBlockedDay ? "inline-block" : "none"};">
                          ${priceForDisplay}
                          <span class="text-xs">${this.defaultPrice.code}</span></span>
                      </div>
                    </div>`
          };
        },
        height: 800
      },
      snowBookingModal: false,
      isShowActionModal: false,
      showExternalEventModal: false,
      currentPayment: null,
      selectedRange: {
        from_date: null,
        to_date: null
      },
      seasonPrices: [],
      loadingCalendarData: true,
      selectedEvent: null,
      daysWithSmartPricing: [],
      commentsOfSelectedRange: []
    };
  },
  mounted() {
    if (window.innerWidth < 640) {
      this.calendarOptions.dayHeaderFormat = { weekday: "short" };
    }
  },
  computed: {
    infoAboutSelectedRange() {
      if (this.selectedRange.start === null) return;

      const possibleTypeStart = this.calendarOptions.events
        .filter((event) => event.extendedProps.type !== 'external')
        .find((event) =>
          dayjs(this.selectedRange.start).isBetween(event.start, event.end, null, "[)")
        );

      const possibleTypeEnd = this.calendarOptions.events
        .filter((event) => event.extendedProps.type !== 'external')
        .find((event) =>
          dayjs(this.selectedRange.end).isBetween(event.start, event.end, null, "(]")
        );

      if (possibleTypeStart) {
        const isEndBetween = dayjs(this.selectedRange.end).isBetween(possibleTypeStart.start, possibleTypeStart.end, null, "(]");
        if (possibleTypeStart && isEndBetween) {
          if (possibleTypeStart.extendedProps.type === "block") {
            // eslint-disable-next-line consistent-return
            return {
              type: possibleTypeStart.extendedProps.inIcal ? "block" : "block_owayy",
              comments: possibleTypeStart.extendedProps.comments
            };
          }
          // eslint-disable-next-line consistent-return
          return {
            type: possibleTypeStart.extendedProps.type,
            comments: possibleTypeStart.extendedProps.comments
          };
        }

        if (possibleTypeStart && !isEndBetween) {
          // eslint-disable-next-line consistent-return
          return {
            type: undefined,
            comments: []
          };
        }
      }
      if (possibleTypeEnd) {
        // eslint-disable-next-line consistent-return
        return {
          type: undefined,
          comments: []
        };
      }
      // eslint-disable-next-line consistent-return
      return {
        type: 'none',
        comments: []
      };
    },
    priceOfSelectedRange() {
      if (this.selectedRange.start === null) return;

      const possiblePriceStart = this.seasonPrices.find((event) =>
        dayjs(this.selectedRange.start).isBetween(event.date_from, event.date_to, null, "[)")
      );

      const possiblePriceEnd = this.seasonPrices.find((event) =>
        dayjs(this.selectedRange.end).isBetween(event.date_from, event.date_to, null, "(]")
      );

      if (
        possiblePriceStart !== undefined &&
        possiblePriceEnd !== undefined &&
        possiblePriceStart.date_from === possiblePriceEnd.date_from
      ) {
        // eslint-disable-next-line consistent-return
        return {
          amount: possiblePriceStart.price,
          code: this.defaultPrice.code,
          title: 'Pricing (special price set for this day(s))'
        };
      }

      const smartPrice = this.daysWithSmartPricing.find((day) => dayjs(day.date).isSame(this.selectedRange.start));

      if (smartPrice) {
        // eslint-disable-next-line consistent-return
        return {
          amount: smartPrice.price,
          code: this.defaultPrice.code,
          title: 'Pricing (special price set for this day(s))'
        };
      }

      // eslint-disable-next-line consistent-return
      return this.defaultPrice;
    }
  },
  methods: {
    eventClick(event) {
      if (event.extendedProps.type === "booking") {
        this.snowBookingModal = true;
        this.currentPayment = event.extendedProps.paymentId;
      }

      if (event.extendedProps.type === "external") {
        this.selectedEvent = event;
        this.showExternalEventModal = true;
      }
    },
    closeModalHandler() {
      this.snowBookingModal = false;
    },
    closeExternalEventModal() {
      this.showExternalEventModal = false;
    },
    selectRange(args) {

      if(args.dateStr){
        args.startStr = args.dateStr;
        this.selectedRange = {
          start: args.dateStr,
          end:args.dateStr
        };

      }else{
        this.selectedRange = {
          start: args.startStr,
          end: args.endStr
        };
      }
    
      if(dayjs(this.selectedRange.start).isSame(this.selectedRange.end)){

        const isoDateTime = new Date(args.date.getTime() - (args.date.getTimezoneOffset() * 60000));

        isoDateTime.setDate(isoDateTime.getDate() + 1);
        this.selectedRange = {
          start: args.dateStr,
          end:isoDateTime.toISOString().replace(/T.*/, '')
        };

        args.endStr = this.selectedRange.end;
      }

      const selectedEvents = this.calendarOptions.events.filter((event) => (
        dayjs(event.start).isBetween(args.startStr, args.endStr, null, "[)") ||
        dayjs(args.startStr).isBetween(event.start, event.end, null, "[)")
      ))

      if (selectedEvents.length === 0 || selectedEvents.length > 1) {
        setTimeout(() => {
          if(!this.snowBookingModal)
            this.isShowActionModal = true;
        }, 100);
      } else if (selectedEvents[0].extendedProps.type === "booking") {
        this.snowBookingModal = true;
        this.currentPayment = selectedEvents[0].extendedProps.paymentId;
      } else if (selectedEvents[0].extendedProps.type === "external") {
        setTimeout(() => {
          this.selectedEvent = selectedEvents[0];
          this.showExternalEventModal = true;
        }, 100);
      } else {
        setTimeout(() => {
          if(!this.snowBookingModal)
            this.isShowActionModal = true;
        }, 100);
      }
    },
    closeActionModalHandler() {
      this.isShowActionModal = false;
    },
    difference(from, to) {
      const diff = dayjs(to).diff(from, "days");
      return diff > 1 ? `${diff} nights` : `${diff} night`;
    }
  }
};
</script>

<style lang="scss">
.fc {
  font-family: "Rubik", sans-serif;

  th {
    .fc-scrollgrid-sync-inner {
      padding: 0 5px;
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
    }
  }

  //.fc-bg-event {
  //  z-index: 0 !important;
  //}

  .fc-day {
    &:after {
      content: "";
      position: absolute;
      top: 0;
      right: 0;
      bottom: 0;
      left: 0;
      &:hover {
        border: 1px solid black;
      }
    }
  }

  //.fc-daygrid-bg-harness {
  //  pointer-events: none;
  //}

  //.fc-day:hover{
  //  &:after {
  //    content: '';
  //    position: absolute;
  //    top: 0;
  //    right: 0;
  //    bottom: 0;
  //    left: 0;
  //    border: 1px solid black;
  //  }
  //}

  .fc-view-harness {
    background-color: white;
  }

  .fc-highlight {
    background: transparent;
    border: 1px solid black;
  }

  .fc-daygrid-day.fc-day-today {
    background-color: transparent;
  }

  .fc-daygrid-day-frame {
    &:hover {
      &:after {
        content: none;
        position: absolute;
        top: 0;
        bottom: 0;
        left: 0;
        right: 0;
        border: 1px solid red;
      }
    }
  }

  .fc-content td:hover {
    background: #adf4fa;
  }

  .fc-toolbar.fc-header-toolbar {
    .fc-toolbar-chunk {
      div[class=""] {
        display: flex;
        width: 300px;
        justify-content: space-between;
      }

      .fc-toolbar-title {
        font-style: normal;
        font-weight: 500;
        font-size: 24px;
        line-height: 32px;
        color: #151514;
      }

      .fc-button.fc-next-button {
        background-color: transparent;
        color: #272727;

        &:hover {
          background-color: transparent;
          color: #272727;
        }

        &:focus {
          box-shadow: none;
        }
      }

      .fc-button.fc-prev-button {
        background-color: transparent;
        color: #272727;

        &:hover {
          background-color: transparent;
          color: #272727;
        }

        &:focus {
          box-shadow: none;
        }
      }

      .fc-button {
        background-color: rgba(255, 255, 255, 0.5);
        color: #151514;
        font-style: normal;
        font-weight: 400;
        font-size: 15px;
        line-height: 19px;
        border: none;
        outline: none;

        &-active {
          background-color: #7e42c3;
          color: white;
        }

        &-primary:focus {
          box-shadow: 0 0 0 0.1rem #7e42c3;
        }

        &:hover {
          background-color: #7e42c3;
          color: white;
        }
      }
    }
  }

  .fc-scrollgrid-sync-inner {
    .fc-col-header-cell-cushion {
      font-style: normal;
      font-weight: 400;
      font-size: 15px;
      line-height: 19px;
      color: #a8a8a8;
      padding: 15px 0 15px 0;
    }
  }

  .calendar-booking,
  .calendar-external {
    font-style: normal;
    font-size: 12px;
    line-height: 14px;
    padding: 5px 10px;
    z-index: 1000;
  }

  .calendar-booking {
    background: transparent;
    font-size: 14px;
    cursor: pointer;
    border: none;
    opacity: 1;

    &:focus {
      outline: none;
      box-shadow: none;
      &:after {
        content: none;
      }
    }
  }

  .calendar-block,
  .calendar-checkout{
    font-weight: 400;
    font-size: 12px;
    line-height: 14px;
    padding: 5px 10px;
    z-index: 0 !important;
  }

  .calendar-external {
    color: black !important;
  }

  .calendar-block,
  .calendar-checkout {
    background: repeating-linear-gradient(
      -45deg,
      rgb(255, 255, 255),
      rgb(255, 255, 255) 8px,
      rgb(221, 221, 221) 8px,
      rgb(221, 221, 221) 9px
    );
  }

  .calendar-external {
    background: repeating-linear-gradient(
      -45deg,
      rgb(220, 220, 220),
      rgb(220, 220, 220) 8px,
      rgb(210, 210, 210) 8px,
      rgb(210, 210, 210) 9px
    );
  }

  .calendar-cell {
    background: transparent;
    position: relative;
    min-width: 200px;
    cursor: pointer;

    &:after {
      content: "";
      position: absolute;
      top: 0;
      right: 0;
      bottom: 0;
      left: 0;
      z-index: 10;
    }

    &:hover {
      &:after {
        content: "";
        position: absolute;
        top: 0;
        right: 0;
        bottom: 0;
        left: 0;
        border: 1px solid black;
      }
    }

    //.fc-daygrid-day-frame {
    //  border: 1px solid transparent;
    //  &:hover {
    //    border: 1px solid red;
    //  }
    //}

    .fc-daygrid-day-top {
      flex-direction: row;
      z-index: 10;
    }
  }

  .calendar-cell-booking {
    background-color: #e8c9ff !important;
  }

  .preparation-before {
    &:after {
      content: "";
      position: absolute;
      top: 0;
      right: 0;
      bottom: 0;
      left: 0;
      background: repeating-linear-gradient(
        -45deg,
        rgb(255, 255, 255),
        rgb(255, 255, 255) 8px,
        rgb(221, 221, 221) 8px,
        rgb(221, 221, 221) 9px
      );
      opacity: 0.5;
    }
  }

  .day-number {
    font-size: 12px;
    padding: 1px 5px;
    background-color: transparent;
    border-radius: 8px;
    display: flex;
    justify-content: space-between;
  }
}

@media screen and (max-width: 640px) {
  .fc .fc-toolbar.fc-header-toolbar {
    display: flex;
    flex-direction: column-reverse;
    align-items: start;


    .fc-toolbar-chunk:nth-child(2) {
      margin: auto;
      padding-bottom: 10px;
    }
  }
}
</style>
