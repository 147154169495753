<template>
  <div class="w-full">
    <div class="animate-pulse">
      <div class="flex flex-col">
        <div class="w-12 h-4 bg-gray-200 rounded-lg mb-1"></div>
        <div class="w-full md:w-48 h-10 bg-gray-200 rounded-lg"></div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "LoadingInputSkeleton"
};
</script>

<style scoped>

</style>
