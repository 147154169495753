<template>
  <div id="calendar-container"
       class="space-y-12  max-w-6xl mx-auto px-4 pb-10 col-span-12 relative">
    <div class="border-b">
      <div class="mx-auto py-6">
        <div class="flex flex-col md:justify-between md:flex-row md:items-center gap-4">
          <h1 class="md:text-xl font-medium leading-7 text-gray-800 text-2xl sm:truncate">
            {{ `Calendar: ${selectedProduct.translation.title}` }}
          </h1>
          <div v-role="['admin']" class="flex flex-col md:flex-row gap-3">
            <div class="w-full md:w-48">
              <!--                            <Listbox v-if="!loadingHosts" key="hosts" v-model="selectedHost" as="div">-->
              <!--                              <ListboxLabel class="block text-sm font-medium text-gray-700">Host</ListboxLabel>-->
              <!--                              <div class="relative mt-1">-->
              <!--                                &lt;!&ndash;                  <ComboboxInput class="w-full rounded-md border border-gray-300 bg-white py-2 pl-3 pr-10 shadow-sm focus:border-highlight-500 focus:outline-none focus:ring-1 focus:ring-highlight-500 sm:text-sm"&ndash;&gt;-->
              <!--                                &lt;!&ndash;                                 :display-value="(host) => (`${host.first_name} ${host.last_name}`)"&ndash;&gt;-->
              <!--                                &lt;!&ndash;                                 @change="hostSearchWord = $event.target.value" />&ndash;&gt;-->
              <!--                                <ListboxButton class="absolute inset-y-0 right-0 flex items-center rounded-r-md px-2 focus:outline-none">-->
              <!--                                  <span class="block truncate">{{ selectedHost.first_name }}</span>-->
              <!--                                  <span-->
              <!--                                    class="pointer-events-none absolute inset-y-0 right-0 flex items-center pr-2">-->
              <!--                                    <SelectorIcon class="h-5 w-5 text-gray-400" aria-hidden="true" />-->
              <!--                                  </span>-->
              <!--                                </ListboxButton>-->

              <!--                                <ListboxOptions v-if="filteredHosts.length > 0" class="absolute z-10 mt-1 max-h-60 w-full overflow-auto rounded-md bg-white py-1 text-base shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none sm:text-sm">-->
              <!--                                  <ListboxOption v-for="host in filteredHosts" :key="host.id" :value="host" as="template" v-slot="{ active, selected }">-->
              <!--                                    <li :class="['group relative cursor-default select-none py-2 pl-3 pr-9',-->
              <!--                                                 active ? 'bg-highlight-600 text-white' : 'text-gray-900']">-->
              <!--                                      <div class="flex">-->
              <!--                                        <span :class="['truncate', selected && 'font-semibold']">-->
              <!--                                          {{ host.first_name }} {{ host.last_name }}-->
              <!--                                        </span>-->
              <!--                                      </div>-->
              <!--                                      <span v-if="selected" :class="['absolute inset-y-0 right-0 flex items-center pr-4',-->
              <!--                                                                     active ? 'text-white' : 'text-highlight-600']">-->
              <!--                                        <CheckIcon class="h-5 w-5" aria-hidden="true" />-->
              <!--                                      </span>-->
              <!--                                    </li>-->
              <!--                                  </ListboxOption>-->
              <!--                                </ListboxOptions>-->
              <!--                              </div>-->
              <!--                            </Listbox>-->
              <Listbox v-if="!loadingHosts" key="hosts" v-model="selectedHost" class="min-w-lg"
                       as="div">
                <ListboxLabel class="block text-sm font-medium text-gray-700">Host</ListboxLabel>
                <div class="relative mt-1">
                  <ListboxButton
                    class="w-full rounded-md border border-gray-300 bg-white py-2 pl-3 pr-10 shadow-sm focus:border-highlight-500 focus:outline-none focus:ring-1 focus:ring-highlight-500 sm:text-sm">
                    <span class="block truncate text-left">
                      {{ selectedHost.first_name
                      }} {{ selectedHost.last_name }}
                    </span>
                    <span
                      class="pointer-events-none absolute inset-y-0 right-0 flex items-center pr-2">
                      <SelectorIcon class="h-5 w-5 text-gray-400" aria-hidden="true" />
                    </span>
                  </ListboxButton>

                  <transition
                    leave-active-class="transition duration-100 ease-in"
                    leave-from-class="opacity-100"
                    leave-to-class="opacity-0">
                    <ListboxOptions
                      v-if="hosts.length > 0"
                      class="absolute z-10 mt-1 max-h-60 w-full overflow-auto rounded-md bg-white py-1 text-base shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none sm:text-sm">
                      <ListboxOption
                        v-slot="{ active, selected }"
                        v-for="host in hosts"
                        :key="host.id"
                        :value="host"
                        as="template">
                        <li
                          :class="['group relative select-none py-2 pl-3 pr-9 cursor-pointer',
                                   active ? 'bg-highlight-600 text-white' : 'text-gray-900']">
                          <span
                            :class="[
                              selected ? 'font-medium' : 'font-normal',
                              'block truncate',
                            ]">
                            {{ host.first_name }} {{ host.last_name }}
                          </span>
                          <span
                            v-if="selected"
                            :class="['absolute inset-y-0 right-0 flex items-center pr-4',
                                     active ? 'text-white' : 'text-highlight-600']">
                            <CheckIcon class="h-5 w-5" aria-hidden="true" />
                          </span>
                        </li>
                      </ListboxOption>
                    </ListboxOptions>
                  </transition>
                </div>
              </Listbox>
              <LoadingInputSkeleton v-else />
            </div>
            <div v-if="loadingProducts || products.length !== 0" class="w-full md:w-48">
              <Listbox v-if="!loadingProducts" key="products" v-model="selectedProduct" as="div">
                <ListboxLabel class="block text-sm font-medium text-gray-700">Product</ListboxLabel>
                <div class="relative mt-1">
                  <ListboxButton
                    class="w-full rounded-md border border-gray-300 bg-white py-2 pl-3 pr-10 shadow-sm focus:border-highlight-500 focus:outline-none focus:ring-1 focus:ring-highlight-500 sm:text-sm">
                    <span class="block truncate text-left">
                      {{ selectedProduct.translation.title
                      }}
                    </span>
                    <span
                      class="pointer-events-none absolute inset-y-0 right-0 flex items-center pr-2">
                      <SelectorIcon class="h-5 w-5 text-gray-400" aria-hidden="true" />
                    </span>
                  </ListboxButton>

                  <transition
                    leave-active-class="transition duration-100 ease-in"
                    leave-from-class="opacity-100"
                    leave-to-class="opacity-0">
                    <ListboxOptions
                      v-if="hosts.length > 0"
                      class="absolute z-10 mt-1 max-h-60 w-full overflow-auto rounded-md bg-white py-1 text-base shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none sm:text-sm">
                      <ListboxOption
                        v-slot="{ active, selected }"
                        v-for="product in products"
                        :key="product.id"
                        :value="product"
                        as="template">
                        <li
                          :class="['group relative select-none py-2 pl-3 pr-9 cursor-pointer',
                                   active ? 'bg-highlight-600 text-white' : 'text-gray-900']"
                          :data-title="product.translation.title">
                          <span
                            :class="[
                              selected ? 'font-medium' : 'font-normal',
                              'block truncate',
                            ]">
                            {{ product.translation.title }}
                          </span>
                          <span
                            v-if="selected"
                            :class="['absolute inset-y-0 right-0 flex items-center pr-4',
                                     active ? 'text-white' : 'text-highlight-600']">
                            <CheckIcon class="h-5 w-5" aria-hidden="true" />
                          </span>
                        </li>
                      </ListboxOption>
                    </ListboxOptions>
                  </transition>
                </div>
              </Listbox>
              <LoadingInputSkeleton v-else />
            </div>
            <div v-if="products.length !== 0 && units.length > 1" class="w-full md:w-48">
              <Listbox v-if="!loadingUnits" key="units" v-model="selectedUnit" as="div">
                <ListboxLabel class="block text-sm font-medium text-gray-700">Unit</ListboxLabel>
                <div class="relative mt-1">
                  <ListboxButton
                    class="w-full rounded-md border border-gray-300 bg-white py-2 pl-3 pr-10 shadow-sm focus:border-highlight-500 focus:outline-none focus:ring-1 focus:ring-highlight-500 sm:text-sm">
                    <span class="block truncate text-left">
                      {{ selectedUnit.translation.title
                      }}
                    </span>
                    <span
                      class="pointer-events-none absolute inset-y-0 right-0 flex items-center pr-2">
                      <SelectorIcon class="h-5 w-5 text-gray-400" aria-hidden="true" />
                    </span>
                  </ListboxButton>

                  <transition
                    leave-active-class="transition duration-100 ease-in"
                    leave-from-class="opacity-100"
                    leave-to-class="opacity-0">
                    <ListboxOptions
                      v-if="units.length > 0"
                      class="absolute z-10 mt-1 max-h-60 w-full overflow-auto rounded-md bg-white py-1 text-base shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none sm:text-sm">
                      <ListboxOption
                        v-slot="{ active, selected }"
                        v-for="unit in units"
                        :key="unit.id"
                        :value="unit"
                        as="template">
                        <li
                          :class="['group relative select-none py-2 pl-3 pr-9 cursor-pointer',
                                   active ? 'bg-highlight-600 text-white' : 'text-gray-900']">
                          <span
                            :class="[
                              selected ? 'font-medium' : 'font-normal',
                              'block truncate',
                            ]">
                            {{ unit.translation.title }}
                          </span>
                          <span
                            v-if="selected"
                            :class="['absolute inset-y-0 right-0 flex items-center pr-4',
                                     active ? 'text-white' : 'text-highlight-600']">
                            <CheckIcon class="h-5 w-5" aria-hidden="true" />
                          </span>
                        </li>
                      </ListboxOption>
                    </ListboxOptions>
                  </transition>
                </div>
              </Listbox>
              <LoadingInputSkeleton v-else />
            </div>
          </div>
          <div v-role="['host']" class="flex flex-col md:flex-row gap-3">
            <div v-if="loadingProducts || products.length !== 0" class="w-full md:w-48">
              <Listbox v-if="!loadingProducts" key="products" v-model="selectedProduct" as="div">
                <ListboxLabel class="block text-sm font-medium text-gray-700">Product</ListboxLabel>
                <div class="relative mt-1">
                  <ListboxButton
                    class="w-full rounded-md border border-gray-300 bg-white py-2 pl-3 pr-10 shadow-sm focus:border-highlight-500 focus:outline-none focus:ring-1 focus:ring-highlight-500 sm:text-sm">
                    <span class="block truncate text-left">
                      {{ selectedProduct.translation.title
                      }}
                    </span>
                    <span
                      class="pointer-events-none absolute inset-y-0 right-0 flex items-center pr-2">
                      <SelectorIcon class="h-5 w-5 text-gray-400" aria-hidden="true" />
                    </span>
                  </ListboxButton>

                  <transition
                    leave-active-class="transition duration-100 ease-in"
                    leave-from-class="opacity-100"
                    leave-to-class="opacity-0">
                    <ListboxOptions
                      v-if="hosts.length > 0"
                      class="absolute z-10 mt-1 max-h-60 w-full overflow-auto rounded-md bg-white py-1 text-base shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none sm:text-sm">
                      <ListboxOption
                        v-slot="{ active, selected }"
                        v-for="product in products"
                        :key="product.id"
                        :value="product"
                        as="template">
                        <li
                          :class="['group relative select-none py-2 pl-3 pr-9 cursor-pointer',
                                   active ? 'bg-highlight-600 text-white' : 'text-gray-900']"
                          :data-title="product.translation.title">
                          <span
                            :class="[
                              selected ? 'font-medium' : 'font-normal',
                              'block truncate',
                            ]">
                            {{ product.translation.title }}
                          </span>
                          <span
                            v-if="selected"
                            :class="['absolute inset-y-0 right-0 flex items-center pr-4',
                                     active ? 'text-white' : 'text-highlight-600']">
                            <CheckIcon class="h-5 w-5" aria-hidden="true" />
                          </span>
                        </li>
                      </ListboxOption>
                    </ListboxOptions>
                  </transition>
                </div>
              </Listbox>
              <LoadingInputSkeleton v-else />
            </div>
            <div v-if="products.length !== 0 && units.length > 1" class="w-full md:w-48">
              <Listbox v-if="!loadingUnits" key="units" v-model="selectedUnit" as="div">
                <ListboxLabel class="block text-sm font-medium text-gray-700">Unit</ListboxLabel>
                <div class="relative mt-1">
                  <ListboxButton
                    class="w-full rounded-md border border-gray-300 bg-white py-2 pl-3 pr-10 shadow-sm focus:border-highlight-500 focus:outline-none focus:ring-1 focus:ring-highlight-500 sm:text-sm">
                    <span class="block truncate text-left">
                      {{ selectedUnit.translation.title }}
                    </span>
                    <span
                      class="pointer-events-none absolute inset-y-0 right-0 flex items-center pr-2">
                      <SelectorIcon class="h-5 w-5 text-gray-400" aria-hidden="true" />
                    </span>
                  </ListboxButton>

                  <transition
                    leave-active-class="transition duration-100 ease-in"
                    leave-from-class="opacity-100"
                    leave-to-class="opacity-0">
                    <ListboxOptions
                      v-if="units.length > 0"
                      class="absolute z-10 mt-1 max-h-60 w-full overflow-auto rounded-md bg-white py-1 text-base shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none sm:text-sm">
                      <ListboxOption
                        v-slot="{ active, selected }"
                        v-for="unit in units"
                        :key="unit.id"
                        :value="unit"
                        as="template">
                        <li
                          :class="['group relative select-none py-2 pl-3 pr-9 cursor-pointer',
                                   active ? 'bg-highlight-600 text-white' : 'text-gray-900']">
                          <span
                            :class="[
                              selected ? 'font-medium' : 'font-normal',
                              'block truncate',
                            ]">
                            {{ unit.translation.title }}
                          </span>
                          <span
                            v-if="selected"
                            :class="['absolute inset-y-0 right-0 flex items-center pr-4',
                                     active ? 'text-white' : 'text-highlight-600']">
                            <CheckIcon class="h-5 w-5" aria-hidden="true" />
                          </span>
                        </li>
                      </ListboxOption>
                    </ListboxOptions>
                  </transition>
                </div>
              </Listbox>
              <LoadingInputSkeleton v-else />
            </div>
          </div>
        </div>
      </div>
    </div>
    <Calendar
      v-if="!loading"
      :hostId="selectedHost.id"
      :productId="selectedProduct.id"
      :unit="selectedUnit"
      :defaultPrice="defaultPrice" />
  </div>
</template>

<script>
import Calendar from "@/components/Calendar/AppCalendar";
import { computed, onMounted, ref, watch } from "vue";
import { useQuery, useQueryLoading } from "@vue/apollo-composable";
import {
  GET_VERIFIED_USERS,
  GET_PRODUCTS_CALENDAR,
  GET_UNITS
} from "@/graphql/calendar/queries";
import { useStore } from "vuex";
import LoadingInputSkeleton from "@/components/UI/Indication/LoadingInputSkeleton";
import { useRoute, useRouter } from "vue-router";
// import { useRouter } from "vue-router";

export default {
  name: "CalendarPage",
  components: {
    LoadingInputSkeleton,
    Calendar
  },
  setup() {
    const store = useStore();
    const route = useRoute();
    const router = useRouter();

    /// Host
    const selectedHost = ref({
      id: null,
      first_name: "",
      last_name: ""
    });

    onMounted(() => {
      if (!store.getters["auth/isAdmin"]) {
        selectedHost.value = {
          id: store.state.auth.user.id,
          first_name: store.state.auth.user.name,
          last_name: ""
        }
      }
    })

    const {
      result,
      loading: loadingHosts,
      onResult: hostResult
    } = useQuery(GET_VERIFIED_USERS, null, () => ({
      enabled: store.getters["auth/isAdmin"],
      fetchPolicy: "network-only"
    }));

    const hosts = computed(() => result?.value?.getVerifiedUsers ?? [{
      first_name: "",
      last_name: ""
    }]);

    hostResult((result) => {
      const { data: { getVerifiedUsers = [] } = {} } = result;
      selectedHost.value = getVerifiedUsers.find(host => host.id ===
        (+route.query.hostId ? +route.query.hostId : store.state.auth.user.id));
    });

    watch(selectedHost, value => {
      router.push({ query: {
          ...route.query,
        hostId: value.id
      } })
    })

    /// Products

    const {
      result: productsItems,
      loading: loadingProducts,
      onResult: productResult
    } = useQuery(GET_PRODUCTS_CALENDAR, () => (
      {
        userId: selectedHost.value.id || null,
        page: 1,
        perPage: 1000,
        sortCreatedAt: -1,
        status: null,
        search: ""
      }
    ), () => ({
      enabled: selectedHost.value.id !== null,
      fetchPolicy: "network-only"
    }));

    const selectedProduct = ref({
      id: null,
      translation: {
        title: ""
      },
      default_price: null,
      currency: null
    });

    const products = computed(() => productsItems?.value?.getProducts.items ?? []);

    productResult(() => {
      const { value: { getProducts: { items = [] } = {} } = {} } = productsItems;
      if (items.length > 0) {
        const f = items.find(product => product.id === +route.query.productId);
        selectedProduct.value = +route.query.productId && f ? f : items[0];
      }
    });

    watch(selectedProduct, value => {
      router.push({ query: {
        ...route.query,
        productId: value.id
      } })
    })

    /// Units
    const {
      result: unitItems,
      loading: loadingUnits,
      onResult: unitsResult
    } = useQuery(GET_UNITS, () => (
      {
        parent_id: selectedProduct.value.id
      }
    ), () => (
      {
        enabled: products.value.length > 0 && selectedProduct.value !== undefined,
        fetchPolicy: "network-only"
      }
    ));

    const selectedUnit = ref({
      id: null,
      translation: {
        title: ""
      }
    });

    const units = computed(() => unitItems?.value?.getUnits ?? []);

    unitsResult(() => {
      const { value: { getUnits = [] } = {} } = unitItems;
      selectedUnit.value = getUnits[0];
    });

    const loading = useQueryLoading();

    const defaultPrice = computed(() => {
      const code = store.getters["product/getCurrencyCode"](selectedProduct.value.currency);
      return {
        amount: selectedProduct.value.default_price,
        code
      };
    });

    const actionKey = computed(() => {
      if (typeof navigator !== "undefined") {
        if (/(Mac|iPhone|iPod|iPad)/i.test(navigator.platform)) {
          return "⌘";
        }
        return "Ctrl + ";
      }
      return "Ctrl + ";
    });

    return {
      actionKey,
      hosts,
      selectedHost,
      loadingHosts,
      loadingProducts,
      products,
      selectedProduct,
      selectedUnit,
      loadingUnits,
      units,
      loading,
      defaultPrice
    };
  }
};
</script>

<style>
[data-title]:hover:after {
  opacity: 1;
  transition: all 0.1s ease 0.5s;
  visibility: visible;
}

[data-title]:after {
  content: attr(data-title);
  position: absolute;
  bottom: -1.6em;
  padding: 3px 3px 3px 6px;
  color: #666;
  -moz-border-radius: 5px;
  -webkit-border-radius: 5px;
  border-radius: 5px;
  -moz-box-shadow: 0px 0px 4px #666;
  -webkit-box-shadow: 0px 0px 4px #666;
  box-shadow: 0px 0px 4px #666;
  background-image: -moz-linear-gradient(top, #f0eded, #bfbdbd);
  background-image: -webkit-gradient(linear, left top, left bottom, color-stop(0, #f0eded), color-stop(1, #bfbdbd));
  background-image: -webkit-linear-gradient(top, #f0eded, #bfbdbd);
  background-image: -moz-linear-gradient(top, #f0eded, #bfbdbd);
  background-image: -ms-linear-gradient(top, #f0eded, #bfbdbd);
  background-image: -o-linear-gradient(top, #f0eded, #bfbdbd);
  opacity: 0;
  z-index: 99999;
  visibility: hidden;
  background-color: white;
}
</style>
