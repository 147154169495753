import gql from "graphql-tag";

const SET_AVAILABLE = gql`
  mutation setAvailable($data: SetUnavailableBookingInput!) {
    setAvailable(data: $data)
  }
`;

const SET_UNAVAILABLE = gql`
  mutation setUnavailable($data: SetUnavailableBookingInput!) {
    setUnavailable(data: $data) {
      id
    }
  }
`;

const SET_SEASON_PRICE = gql`
  mutation setSeasonPrice($data: SetSeasonPriceInput) {
    setSeasonPrice(data: $data) {
      id
    }
  }
`;

const DELETE_CUSTOME_PRICING = gql`
  mutation deleteCustomPricing($id: Int!) {
    deleteCustomPricing(id: $id)
  }
`;

export { SET_AVAILABLE, SET_UNAVAILABLE, SET_SEASON_PRICE, DELETE_CUSTOME_PRICING };
