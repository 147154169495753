<template>
  <TransitionRoot as="template" :show="snowExternalEvent">
    <Dialog as="div" class="relative z-10" @close="$emit('closeModal')">
      <TransitionChild
        as="template"
        enter="ease-in-out duration-500"
        enter-from="opacity-0"
        enter-to="opacity-100"
        leave="ease-in-out duration-500"
        leave-from="opacity-100"
        leave-to="opacity-0">
        <div class="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
      </TransitionChild>

      <div class="fixed inset-0 overflow-hidden">
        <div class="absolute inset-0 overflow-hidden">
          <div class="pointer-events-none fixed inset-y-0 right-0 flex max-w-full pl-10">
            <TransitionChild
              as="template"
              enter="transform transition ease-in-out duration-500 sm:duration-700"
              enter-from="translate-x-full"
              enter-to="translate-x-0"
              leave="transform transition ease-in-out duration-500 sm:duration-700"
              leave-from="translate-x-0"
              leave-to="translate-x-full">
              <DialogPanel class="pointer-events-auto relative w-screen max-w-md">
                <div class="flex h-full flex-col overflow-y-scroll bg-white shadow-xl py-6 px-4 sm:px-6 z-50">
                  <div>
                    <div class="flex justify-between pb-4 border-b">
                      <button class="flex outline-none" @click="$emit('closeModal')">
                        <XIcon class="w-6 h-6" />
                      </button>

                      <div class="py-2">
                        <div class="flex items-center gap-2">
                          <h1 class="font-medium text-universeBlue text-base">Channel : {{ externalEvent.title }}</h1>
                        </div>
                      </div>
                    </div>
                    <div class="pt-6 flex gap-3">
                      <div>
                        <p class="font-medium text-xl">{{ externalEvent.title }}</p>
                      </div>
                    </div>
                    <div class="flex justify-between py-6 border-b">
                      <div class="flex gap-2 items-center">
                        <div class="flex-col flex gap-1">
                          <span class="text-sm text-lightGray">Check-in</span>
                          <span class="text-sm">{{ formatDate(externalEvent.start) }}</span>
                        </div>
                        <ChevronRightIcon class="h-5 w-5 mr-1 text-lightGray" />
                        <div class="flex-col flex gap-1">
                          <span class="text-sm text-lightGray">Check-out</span>
                          <span class="text-sm">{{ formatDate(externalEvent.end) }}</span>
                        </div>
                      </div>
                    </div>
                    <div>
                      <div class="pt-6 flex gap-3">
                        <div>
                          <p>
                            The date(s) are closed by your external calendar <span class="font-medium">{{ externalEvent.title }}</span>.
                            To make the date(s) available at Owayy, please go to your external
                            calendar and make sure that these dates are available.
                            Then go to your Owayy calendar and make the date(s) available.
                          </p>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </DialogPanel>
            </TransitionChild>
          </div>
        </div>
      </div>
    </Dialog>
  </TransitionRoot>
</template>

<script>
import { Dialog, DialogPanel, TransitionChild, TransitionRoot } from "@headlessui/vue";
import { XIcon } from "@heroicons/vue/outline";
import dayjs from "dayjs";

export default {
  name: "ExternalEventModal",
  props: {
    snowExternalEvent: {
      type: Boolean,
      required: true
    },
    externalEvent: {
      type: Object,
      required: true
    }
  },
  emits: ["closeModal"],
  components: {
    Dialog,
    DialogPanel,
    XIcon,
    TransitionChild,
    TransitionRoot
  },
  setup() {
    const formatDate = (date, isDateOnly = false) => {
      const dateTemplate = "D";
      const fullTemplate = "MMM D";
      return dayjs(date).format(isDateOnly ? dateTemplate : fullTemplate);
    };

    return {
      formatDate
    };
  }
};
</script>

<style scoped></style>
