<template>
  <TransitionRoot as="template" :show="snowBookingModal" @keydown.esc="closeModalBooking">
    <Dialog as="div" class="relative z-10" @click="closeModalBooking">
      <TransitionChild as="template" enter="ease-in-out duration-500" enter-from="opacity-0" enter-to="opacity-100" leave="ease-in-out duration-500" leave-from="opacity-100" leave-to="opacity-0">
        <div class="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
      </TransitionChild>

      <div class="fixed inset-0 overflow-hidden">
        <div class="absolute inset-0 overflow-hidden">
          <div class="pointer-events-none fixed inset-y-0 right-0 flex max-w-full pl-10">
            <TransitionChild as="template" enter="transform transition ease-in-out duration-500 sm:duration-700" enter-from="translate-x-full" enter-to="translate-x-0" leave="transform transition ease-in-out duration-500 sm:duration-700" leave-from="translate-x-0" leave-to="translate-x-full">
              <DialogPanel class="pointer-events-auto relative w-screen max-w-xl">
                <div class="flex h-full flex-col overflow-y-scroll bg-white shadow-xl py-6 px-4 sm:px-6 z-50">
                  <div v-if="!$apollo.queries.booking.loading">
                    <div class="flex justify-between pb-4 border-b">
                      <button type="button" class="rounded-md text-gray-300 hover:text-white focus:outline-none focus:ring-2 focus:ring-white" @click="closeModalBooking">
                        <span class="sr-only">Close panel</span>
                        <ChevronLeftIcon class="h-6 w-6 mr-1 text-gray-400" />
                      </button>


                      <div class="py-2">
                        <div class="flex items-center gap-2">
                          <h1 class="font-medium text-universeBlue text-base">
                            Booking #{{ booking.id }}
                          </h1>
                          <div class="absolute md:relative top-2 right-2 md:top-0 md:right-0">
                            <span
                              class="block md:mb-1 inline-flex items-center px-1.5 py-0.5 rounded-full text-xs
                                    font-medium capitalize"
                              :class="booking.status === 'paid' ? 'bg-green-100 text-green-800' : booking.status == 'cancelled'
                                ? 'bg-red-100 text-red-500' : 'border bg-gray-100 text-gray-800'">
                              {{ booking.status }}
                            </span>
                          </div>
                        </div>
                        <div class="flex items-center gap-2">
                          <p class="text-gray-600 text-xs">Placed {{ formatDate(booking.created_at, "D MMM YYYY • HH:mm") }}</p>
                        </div>
                      </div>
                    </div>
                    <div class="pt-6 flex gap-3">
                      <div class="w-24 flex-shrink-0">
                        <div class="aspect-w-12 aspect-h-12">
                          <img v-if="booking.bookings[0].product.parent.media" class="object-cover rounded-lg" :src="`https://owayy.imgix.net/${booking.bookings[0].product.parent.media.media.path}?fm=jp2&w=768&h=768&fit=max&auto=format,compress`" />
                        </div>
                      </div>
                      <div class="flex flex-col gap-1">
                        <router-link :to="{ name: 'Booking', params: {id: booking.uuid}}" class="text-sm text-highlight font-medium">See booking</router-link>
                        <div>
                          <p class="font-medium text-2xl">{{ booking.bookings[0].product.translation.title }}</p>
                        </div>
                      </div>
                    </div>
                    <div class="flex justify-between py-6 border-b">
                      <div class="flex gap-2 items-center">
                        <div class="flex-col flex gap-1">
                          <span class="text-sm text-lightGray">Check-in</span>
                          <span class="text-sm">{{ formatDate(booking.bookings[0].from_date, "DD MMMM YYYY") }}</span>
                        </div>
                        <ChevronRightIcon class="h-5 w-5 mr-1 text-lightGray" />
                        <div class="flex-col flex gap-1">
                          <span class="text-sm text-lightGray">Check-out</span>
                          <span class="text-sm">{{ formatDate(booking.bookings[0].to_date, "DD MMMM YYYY") }}</span>
                        </div>
                      </div>
                      <div class="flex-col flex gap-1">
                        <span class="text-sm text-lightGray">Total Price</span>
                        <span class="text-sm">{{ totalWithoutFee }} {{ currencyCode }}</span>
                      </div>
                    </div>
                    <div class="border-b">
                      <div class="pt-6 flex justify-between">
                        <span class="font-medium text-base">Who’s your Guest</span>
                      </div>
                      <div class="pt-6 flex gap-3">
                        <div class="w-16 flex-shrink-0">
                          <div class="aspect-w-6 aspect-h-6">
                            <img v-if="booking.bookings[0].product.parent.media" class="object-cover rounded-lg" :src="`https://owayy.imgix.net/${booking.bookings[0].product.parent.media.media.path}?fm=jp2&w=768&h=768&fit=max&auto=format,compress`" />
                          </div>
                        </div>
                        <div class="flex flex-col gap-1">
                          <p class="text-sm">{{ booking.customer_info.first_name }} {{ booking.customer_info.last_name }}</p>
                          <div>
                            <p class="text-sm text-lightGray">{{ booking.customer_info.number_of_people }} guests</p>
                          </div>
                        </div>
                      </div>
                      <div v-if="booking.customer_addons.length > 0" class="border-b">
                        <div class="pt-6 pb-6">
                          <div class="pb-4">
                            <span class="font-medium text-base">Addons</span>
                          </div>
                          <div v-for="(add, index) in booking.customer_addons" :key="index" class="border p-3 rounded-lg mb-4">
                            <div class="flex flex-col pb-3">
                              <span class="text-base">{{ add.addon.translation.title }}</span>
                              <span class="text-xs text-lightGray font-normal">{{ add.addon.translation.description }}</span>
                              <span class="text-xs text-lightGray font-normal">x {{ add.qty }} items</span>
                            </div>
                            <span class="text-sm font-normal">{{ add.price }} {{ currencyCode }} <span class="text-xs font-normal text-lightGray">/ per item</span></span>
                          </div>
                        </div>
                      </div>
                      <div class="pt-6">
                        <span class="font-medium text-base">Info Guest</span>
                        <div class="pt-4">
                          <div class="flex gap-1 align-middle">
                            <MailIcon class="w-5 h-5" />
                            <span class="text-sm">{{ booking.customer_info.email }}</span>
                          </div>
                          <div class="flex gap-1 align-middle pt-3">
                            <PhoneIcon class="w-5 h-5" />
                            <span class="text-sm">{{ booking.customer_info.tel }}</span>
                          </div>
                        </div>
                      </div>
                      <div class="pt-6 pb-8">
                        <div class="pb-4">
                          <span class="font-medium text-base">Guest Comment</span>
                        </div>
                        <div class="bg-lightGray-100 p-3 rounded-lg">
                          <p v-if="booking.customer_info?.additional_info" class="text-sm text-gray-900 prose-sm">
                            {{ booking.customer_info.additional_info }}
                          </p>
                          <p v-else class="text-sm text-gray-900 prose-sm">No comment</p>
                        </div>
                      </div>
                    </div>
                    <div>
                      <div class="pt-6 pb-6">
                        <div>
                          <div class="flex justify-between items-center">
                            <p class="font-medium text-base">Internal note</p>
                            <button v-if="!addComment" class="font-medium text-sm text-highlight" @click="toggleAddComment">Add</button>
                          </div>
                          <div class="text-sm text-gray-500 mt-4">
                            <div v-if="addComment" class="mb-3 border-b pb-3">
                              <label
                                for="comment"
                                class="text-sm font-medium text-gray-600">
                                Write a comment
                              </label>
                              <textarea
                                id="comment"
                                v-model="comment"
                                name="comment"
                                class="mt-1 block w-full py-2 px-3 sm:text-sm rounded-md text-gray"
                                :class="true ? 'border border-gray-300 shadow-sm focus:outline-none ' +
                                  'focus:ring-highlight-500 focus:border-highlight-500 text-gray-600'
                                  : 'bg-gray-100 pointer-events-none border-transparent'" />
                              <div class="flex justify-end mt-1">
                                <button
                                  class="mt-2 btn btn-xxs flex justify-center btn-highlight disabled:bg-white disabled:cursor-not-allowed
                                            disabled:text-gray-800 disabled:border disabled:border-gray-300 disabled:opacity-30"
                                  :disabled="!comment"
                                  @click="addCommentToBooking">
                                  Add
                                </button>
                              </div>
                            </div>
                            <div v-if="comments.length > 0" class="max-h-80 overflow-y-scroll relative">
                              <ul class="relative">
                                <li
                                  v-for="(comment, idx) in comments"
                                  :key="comment.id"
                                  class="flex items-top relative gap-2 pb-2">
                                  <span v-if="idx !== comments.length - 1" class="absolute top-4 left-1 h-full w-0.5 bg-gray-200" aria-hidden="true" />
                                  <div class="flex-shrink-0 mt-1.5 h-2.5 w-2.5 rounded-full bg-gray-400 border-2"></div>
                                  <p class="text-gray-600" v-html="comment.content"></p>
                                </li>
                              </ul>
                            </div>
                            <p v-else class="text-sm text-gray-500 mt-4">
                              No internal note
                            </p>
                          </div>
                        </div>
                      </div>
                      <div class="flex gap-2">
                        <button
                          class="my-3 py-2 btn btn-xs flex justify-center text-white bg-highlight-700 w-full disabled:opacity-60 disabled:cursor-not-allowed"
                          :disabled="hasConfirmationMailSend"
                          @click="sendConfirmationEmail">
                          <span v-if="!hasConfirmationMailSend && !isConfirmationMailSending">Resend confirmation email</span>
                          <span v-if="isConfirmationMailSending">Sending..</span>
                          <span v-if="hasConfirmationMailSend && !isConfirmationMailSending" class="flex items-center gap-2"><CheckIcon class="h-5 w-5 -ml-2 text-green-500" />Confirmation email has been send</span>
                        </button>
                        <button
                          class="my-3 py-2 btn btn-xs flex justify-center text-highlight-700 bg-highlight-10 btn-white w-full disabled:opacity-60 disabled:cursor-not-allowed"
                          :disabled="hasWelcomeMailSend"
                          @click="sendWelcomeEmail">
                          <span v-if="!hasWelcomeMailSend && !isWelcomeMailSending">Resend welcome email</span>
                          <span v-if="isWelcomeMailSending">Sending..</span>
                          <span v-if="hasWelcomeMailSend && !isWelcomeMailSending" class="flex items-center gap-2"><CheckIcon class="h-5 w-5 -ml-2 text-green-500" />Welcome email has been send</span>
                        </button>
                      </div>
                    </div>
                  </div>
                  <div v-else class="bg-gray-100 rounded-lg w-full h-full flex items-center justify-center">
                    <LoadingSpinner class="w-10 h-10" />
                  </div>
                </div>
              </DialogPanel>
            </TransitionChild>
          </div>
        </div>
      </div>
    </Dialog>
  </TransitionRoot>
</template>

<script>
import { TransitionRoot, DialogPanel, TransitionChild, Dialog } from "@headlessui/vue";
import { GET_PAYMENT_BY_ID } from "@/graphql/calendar/queries";
import dayjs from "dayjs";
import {
  ADD_COMMENT_TO_BOOKING,
  SEND_CONFIRMATION_EMAIL,
  SEND_WELCOME_EMAIL
} from "@/graphql/orders/mutations";
import LoadingSpinner from "@/components/UI/Indication/LoadingSpinner";

export default {
  name: "BookingSidebarModal",
  components: {
    Dialog,
    LoadingSpinner,
    TransitionRoot,
    DialogPanel,
    TransitionChild
  },
  props: {
    snowBookingModal: {
      type: Boolean,
      required: true
    },
    paymentId: {
      type: [Number, null],
      required: true
    }
  },
  emits: ['closeModal'],
  apollo: {
    booking: {
      query: GET_PAYMENT_BY_ID,
      update: data => data.getPaymentById,
      variables() {
        return {
          payment_id: this.paymentId
        }
      },
      skip() {
        return this.paymentId === null;
      },
      result(data) {
        const { data: { getPaymentById: { bookings } = {} } = {} } = data;
        this.comments = bookings[0].comments;

        //
        // this.bookingRef = {
        //   title: getPaymentById.translation.title,
        //   image: getPaymentById.media.media.path
        // }

      }
    }
  },
  data() {
    return {
      bookingRef: {},
      comment: '',
      comments: [],
      addComment: false,
      hasConfirmationMailSend: false,
      isConfirmationMailSending: false,
      hasWelcomeMailSend: false,
      isWelcomeMailSending: false
    }
  },
  computed: {
    currencyCode() {
      return this.$store.getters['product/getCurrencyCode'](this.booking.currency);
    },
    totalWithoutFee() {
      return this.booking.bookings[0].price + this.sumOfNumbers(this.booking.customer_addons.map((a) => a.price * a.qty));
    }
  },
  methods: {
    sumOfNumbers(numOfArray) {
      let sum = 0;
      for (let i = 0; i < numOfArray.length; i += 1) {
        sum += numOfArray[i];
      }
      return sum
    },
    formatDate(dateString, formatString) {
      const date = dayjs(dateString);
      // Then specify how you want your dates to be formatted
      return date.format(formatString);
    },
    toggleAddComment() {
      this.addComment = !this.addComment;
    },
    addCommentToBooking() {
      if (this.comment === '') return;
      this.$apollo.mutate({
        mutation: ADD_COMMENT_TO_BOOKING,
        variables: {
          bookingId: this.booking.bookings[0].id,
          comment: this.comment
        }
      }).then(() => {
        this.$apollo.queries.booking.refetch({
          payment_id: this.paymentId
        })
      }).finally(() => {
        this.comment = '';
        this.addComment = false;
      })
    },
    sendWelcomeEmail() {
      this.isWelcomeMailSending = true;
      this.$apollo.mutate({
        mutation: SEND_WELCOME_EMAIL,
        variables: {
          id: this.booking.id
        }
      })
      setTimeout(() => {
        this.isWelcomeMailSending = false;
        this.hasWelcomeMailSend = true;
      }, 1000)
    },
    sendConfirmationEmail() {
      this.isConfirmationMailSending = true;
      this.$apollo.mutate({
        mutation: SEND_CONFIRMATION_EMAIL,
        variables: {
          id: this.booking.id
        }
      })
      setTimeout(() => {
        this.isConfirmationMailSending = false;
        this.hasConfirmationMailSend = true;
      }, 1000)
    },
    closeModalBooking() {
      this.$emit('closeModal')
    }
  }
};
</script>

<style scoped>

</style>
