<template>
  <TransitionRoot as="template" :show="showActionModal">
    <Dialog as="div" class="relative z-10" @close="closeModal">
      <TransitionChild
        as="template"
        enter="ease-in-out duration-500"
        enter-from="opacity-0"
        enter-to="opacity-100"
        leave="ease-in-out duration-500"
        leave-from="opacity-100"
        leave-to="opacity-0">
        <div class="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
      </TransitionChild>

      <div class="fixed inset-0 overflow-hidden">
        <div class="absolute inset-0 overflow-hidden">
          <div class="pointer-events-none fixed inset-y-0 right-0 flex max-w-full pl-10">
            <TransitionChild
              as="template"
              enter="transform transition ease-in-out duration-500 sm:duration-700"
              enter-from="translate-x-full"
              enter-to="translate-x-0"
              leave="transform transition ease-in-out duration-500 sm:duration-700"
              leave-from="translate-x-0"
              leave-to="translate-x-full">
              <DialogPanel class="pointer-events-auto relative w-screen max-w-xl">
                <div class="flex h-full flex-col overflow-y-scroll bg-white shadow-xl py-6 px-4 sm:px-6 z-50">
                  <button class="flex outline-none" @click="closeModal">
                    <XIcon class="w-6 h-6" />
                  </button>
                  <div class="text-2xl mt-4">
                    <p>{{ formatDay(range.start) }}-{{ formatDay(range.end, true) }}</p>
                  </div>
                  <div class="mt-8 border-b pb-3">
                    <div>
                      <div class="mx-auto w-full">
                        <RadioGroup v-model="selectedAvailability">
                          <RadioGroupLabel class="sr-only">Server size</RadioGroupLabel>
                          <div class="space-y-2">
                            <RadioGroupOption
                              v-for="item in availability"
                              :key="item.name"
                              as="template"
                              :value="item"
                              @click="clickOption"
                              v-slot="{ active, checked }">
                              <div
                                :class="[
                                  active ? 'bg-highlight' : '',
                                  checked ? 'bg-highlight bg-opacity-75 text-white ' : 'bg-white '
                                ]"
                                class="relative flex cursor-pointer rounded-lg px-5 py-4 shadow-md focus:outline-none">
                                <div class="flex w-full items-center justify-between">
                                  <div class="flex items-center">
                                    <div class="text-sm">
                                      <RadioGroupLabel
                                        as="p"
                                        :class="checked ? 'text-white' : 'text-gray-900'"
                                        class="font-medium">
                                        {{ item.title }}
                                      </RadioGroupLabel>
                                      <RadioGroupDescription
                                        as="span"
                                        :class="checked ? 'text-sky-100' : 'text-gray-500'"
                                        class="inline">
                                        <span>{{ item.description }}</span>
                                      </RadioGroupDescription>
                                    </div>
                                  </div>
                                  <div v-show="checked" class="shrink-0 text-white">
                                    <svg class="h-6 w-6" viewBox="0 0 24 24" fill="none">
                                      <circle cx="12" cy="12" r="12" fill="#fff" fill-opacity="0.2" />
                                      <path
                                        d="M7 13l3 3 7-7"
                                        stroke="#fff"
                                        stroke-width="1.5"
                                        stroke-linecap="round"
                                        stroke-linejoin="round" />
                                    </svg>
                                  </div>
                                </div>
                              </div>
                            </RadioGroupOption>
                          </div>
                        </RadioGroup>
                      </div>
                    </div>
                  </div>
                  <div v-if="selectedAvailability && selectedAvailability.id > 1">
                    <div class="pt-6">
                      <div class="flex justify-between items-center">
                        <p class="font-medium text-gray-800">Internal note</p>
                      </div>
                      <div class="text-sm text-gray-500 mt-4">
                        <div class="border-b pb-3">
                          <label for="comment" class="text-sm font-medium text-gray-600"> Write a comment </label>
                          <textarea
                            id="comment"
                            v-model="comment"
                            name="comment"
                            class="mt-1 block w-full py-2 px-3 sm:text-sm rounded-md text-gray"
                            :class="
                              'border border-gray-300 shadow-sm focus:outline-none ' +
                                'focus:ring-highlight-500 focus:border-highlight-500 text-gray-600'
                            " />
                        </div>
                      </div>
                    </div>
                  </div>
                  <div class="pt-6 flex flex-col">
                    <h2 class="font-medium mb-4 text-gray-800">Pricing</h2>
                    <div>
                      <div class="flex-1 pb-2">
                        <div>
                          <label :for="'smart-price'" class="block text-sm text-gray-700 flex items-center gap-1">
                            {{ price.title ? price.title : "Nightly price" }}
                          </label>
                          <div class="mt-1 flex gap-2">
                            <div
                              class="
                                relative
                                border
                                rounded-md
                                inline-flex
                                cursor-text
                                overflow-hidden
                                focus-within:ring-1 focus-within:ring-highlight-500 focus-within:border-highlight-500
                              ">
                              <input
                                :id="'smart-price'"
                                ref="priceInput"
                                v-model="priceValue"
                                type="number"
                                :disabled="!isPriceTouched"
                                :name="'smart-price'"
                                :class="[
                                  'no-arrows block text-center px-1 py-2 sm:text-md border-none\n' + 'w-20',
                                  isPriceTouched ? 'focus:outline-none focus:ring-0' : 'bg-gray-50 cursor-not-allowed'
                                ]" />
                              <label :for="'smart-price'" class="cursor-text block bg-gray-50 px-3 right-0 flex items-center">
                                <span class="text-gray-600 text-sm"> {{ price.code }} </span>
                              </label>
                            </div>
                            <button
                              type="button"
                              class="w-20 inline-block btn btn-sm flex justify-center hover:bg-gray-50"
                              @click="focusPrice">
                              <p>Edit</p>
                            </button>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div class="flex justify-between align-top">
                    <button
                      class="block text-sm font-medium text-gray-700 hover:bg-gray-50 px-2 rounded-md"
                      @click="backPriceToDefault">
                      Back to default price
                    </button>
                    <button type="button" :disabled="isSaveDisabled" class="btn-save-section" @click="saveInfoForSpecificDays">
                      <LoadingSpinner v-if="actionsSidebarLoading" :outerClass="'w-5 h-5 text-white'" />
                      <p v-else>Save</p>
                    </button>
                  </div>
                </div>
              </DialogPanel>
            </TransitionChild>
          </div>
        </div>
      </div>
    </Dialog>
  </TransitionRoot>
</template>

<script>
import { ref, toRefs, computed, onMounted, watch } from "vue";
import {
  RadioGroup,
  RadioGroupLabel,
  RadioGroupDescription,
  RadioGroupOption,
  TransitionRoot,
  DialogPanel,
  TransitionChild,
  Dialog
} from "@headlessui/vue";
import { XIcon } from "@heroicons/vue/solid";
import { SET_AVAILABLE, SET_SEASON_PRICE, SET_UNAVAILABLE } from "@/graphql/calendar/mutations";
import { useMutation, useMutationLoading } from "@vue/apollo-composable";
import dayjs from "dayjs";
import { useStore } from "vuex";
import LoadingSpinner from "@/components/UI/Indication/LoadingSpinner";

export default {
  name: "CalendarActionsSidebarModal",
  components: {
    LoadingSpinner,
    TransitionRoot,
    TransitionChild,
    RadioGroup,
    RadioGroupLabel,
    RadioGroupOption,
    RadioGroupDescription,
    DialogPanel,
    Dialog,
    XIcon
  },
  props: {
    showActionModal: {
      type: Boolean,
      required: true
    },
    selectedRangeInfo: {
      type: Object
    },
    range: {
      type: Object,
      required: true
    },
    productId: {
      type: [Number, null],
      required: true
    },
    price: {
      type: Object,
      required: true
    }
  },
  emits: ["closeActionModal", "refetchEvents"],
  setup(props, { emit }) {
    const store = useStore();

    const { range, productId, price } = toRefs(props);

    const priceValue = ref(price.value.amount);
    const isPriceTouched = ref(false);
    const isPriceChanged = ref(false);
    const priceInput = ref(null);

    watch(priceValue, () => {
      isPriceChanged.value = true;
    });

    const focusPrice = () => {
      isPriceTouched.value = true;
      setTimeout(() => priceInput.value.focus(), 50);
    };

    const availability = {
      none: {
        id: 1,
        title: "Available",
        description: "This date is available for booking."
      },
      block: {
        id: 2,
        title: "Unavailable",
        description: "This will block the dates from any bookings."
      },
      block_owayy: {
        id: 3,
        title: "Unavailable (only on Owayy)",
        description: "This box will only block the date on Owayy and will not send the reservation in other synced calendar(s)."
      },
      checkout: {
        id: 4,
        title: "Only Check-out",
        description: "If this block is checked it will only be possible to check-out on this date and not check in."
      }
    };

    const comment = ref(props.selectedRangeInfo.comments.length > 0 ? props.selectedRangeInfo.comments[0].content : "");

    const isCommentChanged = ref(false);

    watch(comment, () => {
      isCommentChanged.value = true;
    })


    const selectedAvailability = ref(null);

    onMounted(() => {
      if (props.selectedRangeInfo.type) {
        selectedAvailability.value = availability[props.selectedRangeInfo.type];
      }
    });

    const isOptionChanged = ref(false);

    const clickOption = () => {
      isOptionChanged.value = true;
    };

    const { mutate: setAvailable } = useMutation(SET_AVAILABLE, () => ({
      variables: {
        data: {
          from_date: range.value.start,
          to_date: range.value.end,
          product_id: productId.value
        }
      }
    }));

    const { mutate: setUnavailable } = useMutation(SET_UNAVAILABLE);

    const { mutate: setSeasonPrice } = useMutation(SET_SEASON_PRICE);

    const actionsSidebarLoading = useMutationLoading();

    const currencyCode = computed(() => store.getters["product/getCurrentCurrencyCode"]);

    const closeModal = () => {
      selectedAvailability.value = null;
      emit("closeActionModal");
    };

    const saveInfoForSpecificDays = async () => {
      if (selectedAvailability.value !== null) {
        if (selectedAvailability.value.id === 1) {
          await setAvailable();
        } else if (selectedAvailability.value.id === 2) {
          await setUnavailable({
            data: {
              from_date: range.value.start,
              to_date: range.value.end,
              product_id: productId.value,
              only: null,
              inIcal: true,
              comment: comment.value
            }
          });
        } else if (selectedAvailability.value.id === 3) {
          await setUnavailable({
            data: {
              from_date: range.value.start,
              to_date: range.value.end,
              product_id: productId.value,
              only: null,
              inIcal: false,
              comment: comment.value
            }
          });
        } else if (selectedAvailability.value.id === 4) {
          await setUnavailable({
            data: {
              from_date: range.value.start,
              to_date: range.value.end,
              product_id: productId.value,
              only: 1,
              inIcal: false,
              comment: comment.value
            }
          });
        }
      }

      if (isPriceTouched.value) {
        await setSeasonPrice({
          data: {
            from_date: range.value.start,
            to_date: range.value.end,
            price: priceValue.value,
            product_id: productId.value
          }
        });
      }

      await emit("refetchEvents");
      await closeModal();
      isPriceTouched.value = false;
      isPriceChanged.value = false;
      isOptionChanged.value = false;
    };

    const backPriceToDefault = async () => {
      await setSeasonPrice({
        data: {
          from_date: range.value.start,
          to_date: range.value.end,
          price: 0,
          product_id: productId.value
        }
      });

      await emit("refetchEvents");
      await closeModal();
      isPriceTouched.value = false;
      isPriceChanged.value = false;
      isOptionChanged.value = false;
      isCommentChanged.value = false;
    };

    const isSaveDisabled = computed(() => !(isOptionChanged.value || isPriceChanged.value || isCommentChanged.value));

    const formatDay = (date, isDateOnly = false) => {
      const dateTemplate = "D";
      const fullTemplate = "MMM D";
      return dayjs(date).format(isDateOnly ? dateTemplate : fullTemplate);
    };


    return {
      setAvailable,
      setUnavailable,
      currencyCode,
      priceValue,
      saveInfoForSpecificDays,
      formatDay,
      availability,
      selectedAvailability,
      actionsSidebarLoading,
      closeModal,
      priceInput,
      focusPrice,
      isPriceTouched,
      isPriceChanged,
      clickOption,
      isSaveDisabled,
      backPriceToDefault,
      comment
    };
  }
};
</script>

<style scoped></style>
